import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { editEvent, saveAsDraft, saveLocalForm } from 'redux/ducks/Post/actions';
import { postValid, isUpdatingNewLocation } from 'helpers/post';
import ConfirmationModal from 'components/V2/ConfirmationModal';
import { validateForm } from 'redux/ducks/Post';
import { withRouter } from 'react-router-dom';
import { completedEAP } from 'helpers/post';
import moment from 'moment';
import FlashMessage from 'components/FlashMessage';
import SignUpModal from 'scenes/V2/SignUpModal';
import LogInModal from 'scenes/V2/LogInModal';
import { trackMixpanelPageView } from 'helpers/post';
import { MILITARY_FORMAT } from 'helpers/datetime';

export type CreationSteps =
  | 'setting'
  | 'setting-detail`'
  | 'profile'
  | 'location'
  | 'schedule/recurring'
  | 'schedule/one-time'
  | 'location-setup'
  | 'rate'
  | 'schedule/type'
  | 'details/details'
  | 'details/credentials'
  | 'details/supplies'
  | 'eap/eap-type'
  | 'eap/review'
  | 'review'
  | 'schedule-summary';

type Props = {
  currentStep: CreationSteps,
  onBack: Function,
  onSaveAndContinue: Function,
  onSaveAsDraft: Function,
  onPublish?: Function,
  backTo: CreationSteps,
  saveLocalForm: Function,
  updateJobCallback: Function,
  backDisabled?: boolean,
  nextDisabled?: () => boolean,
  authenticated: boolean,
  nextText?: string,
};

class ActionBar extends Component<Props> {
  state = {
    editConfirmedModal: false,
    signUpModal: false,
    loginModal: false,
    pendingAction: null,
    rateIncreaseModal: false,
    increaseJobsRate: false,
  };
  toggleEditConfirmedModal = () =>
    this.setState({ editConfirmedModal: !this.state.editConfirmedModal });

  toggleSignUpModal = () =>
    this.setState((prevState) => ({
      ...prevState,
      signUpModal: !prevState.signUpModal,
      loginModal: false,
    }));

  toggleLogInModal = () =>
    this.setState((prevState) => ({
      ...prevState,
      signUpModal: prevState.loginModal,
      loginModal: !prevState.loginModal,
    }));

  toggleRateIncreaseModal = () =>
    this.setState((prevState) => ({
      ...prevState,
      rateIncreaseModal: !prevState.rateIncreaseModal,
    }));

  onSubmitLogIn = ({ email, password, rememberMe, role }) => {
    this.props.login({
      email: email,
      password: password,
      rememberMe: rememberMe,
      role: role,
      history: this.props.history,
    });
  };

  saveAndContinue = () => {
    if (this.props.currentStep === 'details/credentials' && !this.props.authenticated) {
      this.toggleSignUpModal();
      this.props.saveLocalForm();
      return;
    }

    if (!this.props.authenticated) this.props.saveLocalForm();

    this.props.onSaveAndContinue();
  };

  updateDisabled = () => {
    const {
      post,
      authenticated,
      incompleteNewLocation,
      editMode,
      nextDisabled = () => false,
    } = this.props;
    const hasCompletedEAP = completedEAP(post.eap, post.shifts);

    return (
      !post.shifts.every(
        (s) =>
          moment(s.startTime, MILITARY_FORMAT).isValid() &&
          moment(s.endTime, MILITARY_FORMAT).isValid()
      ) ||
      !hasCompletedEAP ||
      !postValid(post) ||
      !authenticated ||
      (editMode && incompleteNewLocation) ||
      nextDisabled()
    );
  };

  onUpdateEvent = () => {
    if (this.props.post.details.locationsNo !== this.props.post.locations.length) {
      this.saveAndContinue();
      return;
    }

    if (this.updateDisabled()) {
      this.props.validateForm('shifts');
      this.props.validateForm('locations');
      this.props.validateForm('rate');
      this.props.validateForm('post');
      return;
    }

    if (!!this.props.showJobPayRateModal) {
      this.toggleRateIncreaseModal();
      return;
    }

    this.toggleEditConfirmedModal();
  };

  onSave = () => {
    const { updateJobCallback } = this.props;
    this.toggleEditConfirmedModal();
    if (updateJobCallback && !updateJobCallback()) return;

    if (!!this.state.increaseJobsRate)
      this.props.onUpdateEvent({ history: this.props.history, shiftIds: this.props.shiftIds });
    else this.props.onUpdateEvent({ history: this.props.history });
  };

  onContinueEditing = () => {
    this.toggleEditConfirmedModal();
    this.saveAndContinue();
  };

  onContinueSave = (increaseJobsRate) => {
    this.setState({ increaseJobsRate });
    this.toggleEditConfirmedModal();
  };

  ignoredSteps = (step) => {
    return ['setting', 'setting-detail'].includes(step);
  };

  showEditButton = (step) => {
    return (
      this.props.editMode &&
      [
        'setting',
        'setting-detail',
        'profile',
        'location',
        'schedule/recurring',
        'schedule/one-time',
        'location-setup',
        'rate',
        'schedule/type',
        'details/details',
        'details/credentials',
        'details/supplies',
        'eap/eap-type',
        'eap/review',
        'review',
        'schedule-summary',
      ].includes(step)
    );
  };

  renderEditActions = () => {
    const status = this.props.post.navigation.requestStatus;
    const {
      currentStep,
      nextDisabled = () => false,
      nextText = 'SAVE & CONTINUE',
      authenticated,
      editMode,
      incompleteNewLocation,
    } = this.props;

    if (!authenticated || !editMode || !this.showEditButton(currentStep)) return null;

    return (
      <React.Fragment>
        {!['setting', 'setting-detail'].includes(currentStep) && (
          <Button
            onClick={this.onUpdateEvent}
            className={classNames('actionBar__continue', {
              disabled:
                nextDisabled() || (editMode && incompleteNewLocation) || this.updateDisabled(),
            })}
            block
            color="success"
            disabled={
              status === 'loading' || (editMode && incompleteNewLocation) || this.updateDisabled()
            }
          >
            <div>UPDATE JOB</div>
          </Button>
        )}
        {currentStep !== 'update' && (
          <Button
            onClick={this.saveAndContinue}
            className={classNames('actionBar__save__and_continue', {})}
            color="link"
            disabled={status === 'loading'}
          >
            <div>{nextText}</div>
          </Button>
        )}
      </React.Fragment>
    );
  };

  renderUnauthenticatedActions = () => {
    const { nextDisabled = () => false, editMode, authenticated, eventTitle } = this.props;
    if (authenticated || editMode) return null;

    return (
      <React.Fragment>
        <Button
          onClick={this.saveAndContinue}
          className={classNames('actionBar__continue', { disabled: nextDisabled() })}
          block
          color="success"
        >
          <div>SAVE & CONTINUE</div>
        </Button>
        {!!eventTitle && (
          <Button
            className={classNames('actionBar__save__and_continue', {})}
            color="link"
            onClick={() => {
              this.setState((prevState) => ({
                ...prevState,
                signUpModal: true,
                loginModal: false,
                pendingAction: 'saveAsDraft',
              }));
            }}
          >
            <div>SAVE & FINISH LATER</div>
          </Button>
        )}
      </React.Fragment>
    );
  };

  onSaveAndFinishLater = (callback) => {
    const { post } = this.props;

    this.props.saveAsDraft(post, callback);
  };

  renderCreationActions = () => {
    const {
      currentStep,
      nextDisabled = () => false,
      nextText = 'SAVE & CONTINUE',
      eventTitle,
      post,
      authenticated,
      editMode,
    } = this.props;
    const status = post.navigation.requestStatus;

    if (!authenticated || editMode) return null;

    return (
      <React.Fragment>
        <Button
          onClick={this.saveAndContinue}
          className={classNames('actionBar__continue', { disabled: nextDisabled() })}
          block
          color="success"
          disabled={status === 'loading'}
        >
          <div>{nextText}</div>
        </Button>
        {!this.ignoredSteps(currentStep) && !!eventTitle && (
          <Link
            className="actionBar__save__draft"
            to="/dashboard"
            onClick={() => this.onSaveAndFinishLater(() => {})}
            disabled={status === 'loading'}
          >
            <div>SAVE & FINISH LATER</div>
          </Link>
        )}
      </React.Fragment>
    );
  };

  componentDidUpdate(prevProps) {
    const callback = () => this.props.history.push('/dashboard');
    if (
      this.state.pendingAction === 'saveAsDraft' &&
      !prevProps.authenticated &&
      this.props.authenticated
    ) {
      this.onSaveAndFinishLater(callback);
    }
    if (!prevProps.authenticated && this.props.authenticated) {
      if (this.state.pendingAction === 'saveAsDraft') this.onSaveAndFinishLater(callback);
      else this.onSaveAndFinishLater();
    }
  }

  trackAT = () => {
    trackMixpanelPageView('ATSignupHyperlink');
  };

  render() {
    const { backDisabled, disableFlash, currentStep } = this.props;

    return (
      <Fragment>
        {!disableFlash && !this.state.signUpModal && !this.state.loginModal && (
          <FlashMessage onlyErrors withFixedHeader={!this.props.authenticated} noNavBar={false} />
        )}
        <div className="actionBar">
          <div
            className={classNames('actionBar__left', {
              actionBar__left__typeScreen: currentStep === 'type',
            })}
          >
            {currentStep === 'type' && (
              <a
                className="actionBar__left__atSignUp"
                href={`${process.env.REACT_APP_DEEPLINK_URL}/sign-up`}
                onClick={this.trackAT}
              >
                ARE YOU AN ATHLETIC TRAINER? CLICK HERE.
              </a>
            )}
            {!backDisabled && currentStep !== 'type' && (
              <Link
                className="actionBar__left__back"
                to={this.props.backTo}
                onClick={this.props.onBack}
              >
                Back
              </Link>
            )}
          </div>
          <div className="actionBar__right gotham-bold-font">
            {this.renderUnauthenticatedActions()}
            {this.renderCreationActions()}
            {this.renderEditActions()}
            <ConfirmationModal
              isOpen={this.state.editConfirmedModal}
              toggle={this.toggleEditConfirmedModal}
              title="Edit Confirmed"
              body="Your changes have been saved."
              confirmText="Close"
              cancelText="Continue Editing"
              onSave={this.onSave}
              onCancel={this.onContinueEditing}
            />
          </div>
        </div>
        <SignUpModal
          isOpen={this.state.signUpModal}
          toggleSignUpModal={this.toggleSignUpModal}
          toggleLogInModal={this.toggleLogInModal}
          history={this.props.history}
          currentStep={currentStep}
        />
        <LogInModal
          {...this.props}
          isOpen={this.state.loginModal}
          toggleLogInModal={this.toggleLogInModal}
          onSubmit={this.onSubmitLogIn}
          from="action-bar"
        />
        <ConfirmationModal
          isOpen={this.state.rateIncreaseModal}
          toggle={this.toggleRateIncreaseModal}
          title="Pay Rate Increase"
          body="Do you want to increase the pay for all shifts in this job, including those with applicants?"
          onSave={() => this.onContinueSave({ increaseJobsRate: true })}
          cancelText={
            <React.Fragment>
              <div>No, future</div>
              <div>applicants only</div>
            </React.Fragment>
          }
          confirmText={
            <React.Fragment>
              <div>Yes,</div>
              <div>all applicants</div>
            </React.Fragment>
          }
          onCancel={() => this.onContinueSave({ increaseJobsRate: false })}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  authenticated: state.session.authenticated,
  eventTitle: state.post.profile.title,
  post: state.post,
  editMode: !!ownProps.editMode,
  incompleteNewLocation: state.post.locations.some((loc) =>
    isUpdatingNewLocation(loc, state.post.shifts)
  ),
  user: state.session.currentUser,
  rateTypes: state.enums.rateTypes,
});

const mapDispatchToProps = (dispatch) => ({
  saveLocalForm: () => dispatch(saveLocalForm()),
  saveAsDraft: (post, callback) => dispatch(saveAsDraft({ post, callback, from: 'action-bar' })),
  onUpdateEvent: (payload) => dispatch(editEvent(payload)),
  validateForm: (step) => dispatch(validateForm(step)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActionBar));
